
.menu > .ui.menu {
    border-radius: 0;
    box-shadow: none;
}

.app-content {
    display: flex;
}

.app-content > .ui.menu {
    margin: 0;
}

